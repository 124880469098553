import clsx from 'clsx';
import Image from 'next/image';

interface DynamicSvgIcon {
  iconName: string;
  className?: string;
  alt?: string;
  height?: number;
  width?: number;
}

const DynamicSvgIcon = ({
  iconName,
  className,
  alt,
  height = 20,
  width = 20,
}: DynamicSvgIcon) => {
  const iconPathSrc = `/images/icon-svgs/${iconName?.toLowerCase()}.svg`;

  const iconClass = clsx(
    'rounded-none p-1',
    `min-h-[${height}px] min-w-[${width}px]`,
    className,
  );

  return (
    <Image
      className={iconClass}
      src={iconPathSrc}
      alt={alt ?? `${iconName}`}
      width={height}
      height={width}
    />
  );
};

export default DynamicSvgIcon;
